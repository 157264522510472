window.addEventListener("load", () => {
    const loader = document.querySelector(".loader");
    if (loader) {
      loader.classList.add("loader--hidden");
      loader.addEventListener("transitionend", () => {
        loader.remove();
      });
    }
  });
  
  $(".product-carousel").slick({
    lazyLoad: "ondemand",
    autoplay: true,
    autoplaySpeed: 1200,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: '<i class="arrow right">',
    prevArrow: '<i class="arrow left">',
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  });
  
  $(".product-carousell").slick({
    lazyLoad: "ondemand",
    autoplay: true,
    autoplaySpeed: 1400,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: '<i class="arrow right">',
    prevArrow: '<i class="arrow left">',
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  });

  

  /*document.addEventListener(
    "contextmenu",
    function (e) {
      e.preventDefault();
    },
    false
  );*/
  
  /* By @lepotoriko */